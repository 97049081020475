<!--
 * @Author: LiuXin
 * @Date: 2021-09-24 18:11:59
 * @LastEditors: Yran
 * @LastEditTime: 2021-09-27 18:28:33
-->
<template>
  <!-- 产品信息新增编辑会话框 -->
  <a-modal class="product-modal" v-bind="modal" @ok="handelOk" @cancel="closeModal">
    <a-form ref="productFormRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <a-row>
        <a-col :span="24">
          <a-form-item label="客户名称" required>
            <a-select v-model:value="formData.companyCode" placeholder="请选择客户名称" style="width: 100%" v-bind="$filterOptions">
              <a-select-option :value="item.companyCode" :label="item.customerOfCompanyName" v-for="(item, index) in allCustomerList" :key="index">{{ item.customerOfCompanyName }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-if="true" label="上级产品">
            <a-select allowClear v-model:value="formData.parentProjectName"  placeholder="请选择客户名称，主产品无需该内容" style="width: 100%" v-bind="$filterOptions">
              <a-select-option :value="item.projectName" :label="item.projectName" v-for="(item, index) in allProductList" :key="index">{{ item.projectName }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="!isEdit">
        <a-col :span="24">
          <a-form-item label="产品名称">
            <PlusCircleOutlined class="ml10 cursorPoi blue-icon" @click="addProductNameList" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-for="(item, index) in formData.projectNameList" :key="item">
        <a-col :span="24">
          <a-form-item :label="`产品${isEdit ? '名称' : index + 1}`" required>
            <a-input v-model:value.trim="item.projectName" placeholder="请输入产品名称" />
            <DeleteOutlined v-if="!isEdit && formData.projectNameList.length > 1" @click="deleteProductNameList(index)" class="position-abs ml10 cursorPoi" style="top: 0" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="所属行业">
            <a-cascader v-model:value="item.industry" :options="departmentOptions" :field-names="{ value: 'id', label: 'value' }" placeholder="请选择所属行业" style="width: 100%" v-bind="$filterOptions"> </a-cascader>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { PlusOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { saveAddProduct, saveUpdateProduct, getIndustryList } from '@/api/customer/product.js';
import _ from 'lodash';
export default {
  name: '',
  components: { PlusCircleOutlined, DeleteOutlined },
  props: {
    visible: { type: Boolean, default: false },
    productInfo: { type: Object, default: () => {} },
    allCustomerList: { type: Array, default: () => [] },
    allProductList: { type: Array, default: () => [] },
  },
  emits: ['update:visible', 'afterSave'],
  data() {
    return {
      btnLoading: false, //确定按钮状态
      newItem: {
        firstIndustryId: 0,
        projectName: '',
        secondIndustryId: 0,
        industry: [],
      },
      departmentOptions: [], //所属行业列表
      formData: {}, //表单数据
    };
  },
  computed: {
    //  是否是编辑产品信息弹框
    isEdit() {
      return Boolean(this.productInfo.id);
    },
    //  弹出框配置
    modal() {
      return {
        okButtonProps: { loading: this.btnLoading },
        visible: this.visible,
        title: `${this.isEdit ? '编辑' : '新增'}产品信息`,
        width: 600,
        maskClosable: false,
      };
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = {
          companyCode: this.productInfo.companyCode,
          parentProjectName: this.productInfo.parentProjectName,
          parentProjectCode: this.productInfo.parentProjectCode,
          id: this.productInfo.id,
          projectNameList: [
            {
              firstIndustryId: this.productInfo.firstIndustryId,
              projectName: this.productInfo.projectName,
              secondIndustryId: this.productInfo.secondIndustryId,
              industry: [this.productInfo.firstIndustryId, this.productInfo.secondIndustryId],
            },
          ],
        };
      }
      // 只获取所属行业一次
      if (val && this.departmentOptions.length == 0) {
        this.getIndustryList();
      }
    },
  },
  methods: {
    parentProjectNameChange(){
      console.error("=====================parentProjectNameChange==========================")
      if(this.formData.parentProjectName){
        for (let allProductListElement of this.allProductList) {
          if(allProductListElement.projectName === this.formData.parentProjectName){
            this.formData.parentProjectCode =  allProductListElement.projectCode;
          }
        }
      }else {
        this.formData.parentProjectName =  null;
        this.formData.parentProjectCode =  null;
      }
    },
    // 点击确定
    async handelOk() {
      const { flag, postData } = this.checkData();
      this.btnLoading = true;
      let res = {};
      if (flag && this.isEdit) {
        res = await saveUpdateProduct(postData);
      } else if (flag && !this.isEdit) {
        res = await saveAddProduct(postData);
      }
      this.btnLoading = false;
      if (res.code === 0) {
        this.$emit('afterSave');
        this.closeModal();
        this.$message.success('保存成功！');
      }
    },
    // 校验数据
     checkData() {
      let flag = true;
      this.parentProjectNameChange();
      let postData = _.cloneDeep(this.formData);

      if (postData.companyCode) {
        for (let item of postData.projectNameList) {
          if (!item.projectName) {
            flag = false;
            this.$message.warning('产品名称不能为空！');
            break;
          }
          item.firstIndustryId = item.industry[0];
          item.secondIndustryId = item.industry[1];
          delete item.industry;
        }
        // if(postData.parentProject){
        //   postData.parentProjectName =  postData.parentProject.projectName;
        //   postData.parentProjectCode =  postData.parentProject.projectCode;
        //   postData.parentProject = undefined;
        // }
        if (this.isEdit) {
          postData.projectName = postData.projectNameList[0].projectName;
          // postData.projectCode = this.productInfo.projectCode;
          // postData.firstIndustryId = postData.projectNameList[0].firstIndustryId;
          // postData.secondIndustryId = postData.projectNameList[0].secondIndustryId;
          // delete postData.projectNameList;
        }
      } else {
        flag = false;
        this.$message.warning('客户名称不能为空！');
      }
      return { flag, postData };
    },
    // 关闭弹框
    closeModal() {
      this.$emit('update:visible', false);
    },
    // 添加产品项
    addProductNameList() {
      this.formData.projectNameList.push({ ...this.newItem });
      this.key = new Date().getTime();
    },
    // 删除产品项
    deleteProductNameList(index) {
      this.formData.projectNameList.splice(index, 1);
    },
    // 获取所属行业树形列表
    async getIndustryList() {
      let res = await getIndustryList();
      this.departmentOptions = res.data;
    },
  },
};
</script>

<style scoped lang="less">
.ant-form {
  width: 500px;
  padding-right: 25px;
  box-sizing: border-box;
  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  :deep(.ant-form-item) {
    margin-bottom: 12px;
    .ant-form-item-label {
      line-height: 40px;
    }
    .ant-input {
      height: 32px;
    }
  }
}
</style>
