<!--
 * @Author: LiuXin
 * @Date: 2022-02-09 13:59:54
 * @LastEditors: Yran
 * @LastEditTime: 2022-02-10 17:59:59
-->
<template>
  <a-modal :visible="visible" title="利益点" @cancel="closeModal" @ok="onHandleOk">
    <ul class="profit-list">
      <li class="profit-item" v-for="(profit, index) in profitList" :key="profit.index">
        <span class="index">{{ index + 1 }}</span>
        <span class="name" :contenteditable="profit.editable" @dblclick="profit.editable = true" @blur="e => editBlur(e, profit)">{{ profit.name }}</span>
        <span class="length">{{ profit.name.length }}/20</span>
        <a class="operation" @click="deleteProfit"> 删除</a>
      </li>
      <li class="profit-item add-item">
        <span class="index">{{ profitList.length + 1 }}</span>
        <span class="name" @click="addProfit"> + 新增利益点</span>
      </li>
    </ul>
  </a-modal>
</template>

<script>
import { reactive, shallowReactive, shallowRef, watch } from 'vue';
import { getProjectInfoById } from '@/api/customer/product.js';
export default {
  name: 'ProfitModal',
  components: {},
  props: {
    visible: { type: Boolean, default: false },
    selectRowData: { type: Object, default: () => {} },
  },
  emits: ['update:visible', 'addLabelValues'],
  setup(props, { emit }) {
    let profitList = reactive([]);
    // 获取数据
    const getProfitList = async () => {
      let res = await getProjectInfoById(props.selectRowData?.projectCode || '');
      let list = [];
      if (res.code == 0) {
        list = res.data?.label?.split(',') || [];
      }

      list = list?.map(item => {
        return { name: item };
      });
      if (list.length == 0) list = [{ name: '请点击输入利益点', editable: true }];
      profitList.splice(0, profitList.length, ...list);
    };
    // 删除数据
    const deleteProfit = (profit, index) => {
      // todo
      profitList.splice(index, 1);
    };
    // 新增数据
    const addProfit = (profit, index) => {
      // todo
      profitList.push({ name: '请点击输入利益点', editable: true });
    };
    // 编辑
    const editBlur = (e, profit) => {
      let newValue = e.target.innerText;
      profit.name = newValue;
      // todo
      profit.editable = false;
    };
    // 关闭弹窗
    const closeModal = () => {
      emit('update:visible', false);
    };
    // 确认新增
    const onHandleOk = () => {
      let labelValues = '';
      profitList.forEach(item => {
        labelValues += item.name + ',';
      });
      labelValues = labelValues.replace(/.$/, '');
      emit('addLabelValues', labelValues);

      closeModal();
    };
    watch(
      () => props.visible,
      val => {
        if (val) {
          getProfitList();
        }
      },
      { immediate: true }
    );

    return {
      profitList,
      closeModal,
      deleteProfit,
      editBlur,
      addProfit,
      onHandleOk,
    };
  },
};
</script>

<style scoped lang="less">
.profit-list {
  height: 350px;
  overflow: auto;
  .profit-item {
    line-height: 30px;
    display: flex;
    .index {
      margin-right: 10px;
    }
    .name {
      flex-grow: 1;
      &:focus-visible {
        border: 1px solid #999;
        outline: 0;
        box-sizing: border-box;
        height: 30px;
        line-height: 28px;
      }
    }
    .length {
      margin: 0 10px;
    }
  }
  .add-item .name {
    text-align: center;
    background: rgb(242, 242, 242);
    cursor: pointer;
  }
}
</style>
