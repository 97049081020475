<!--
 * @Author: Yran
 * @Date: 2021-05-20 13:55:30
 * @LastEditors: Yran
 * @LastEditTime: 2022-02-10 17:40:15
-->
<template>
  <div class="product_manage w100 h100">
    <!-- 产品条件筛选 -->
    <div class="flexJustifyBetween filter_row">
      <a-row class="w100">
        <a-col :span="1" style="line-height: 40px; min-width: 46px">筛选：</a-col>
        <a-col :span="4">
          <a-form-item :wrapper-col="{ span: 24 }" class="mb0">
            <a-select v-model:value="productFilter.customerNameList" mode="tags" option-filter-prop="label" placeholder="请选择客户名称" style="width: 100%" ref="customerSelect" :max-tag-count="2" @blur="customerSelectTags('客户名称', 'productFilter', 'customerCodeList')" allow-clear show-arrow show-search>
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option :value="item.customerOfCompanyName" :label="item.customerOfCompanyName" v-for="(item, index) in allCustomerList" :key="index">{{ item.customerOfCompanyName }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-select v-model:value="productFilter.projectNameList" mode="tags" option-filter-prop="label" placeholder="请选择产品名称" style="width: 100%" ref="productSelect" :max-tag-count="2" @blur="ProductSelectTags" allow-clear show-arrow show-search>
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option :value="item.projectName" :label="item.projectName" v-for="(item, index) in allProductList" :key="index">{{ item.projectName }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-select v-model:value="productFilter.channelTypeList" mode="tags" :options="mediaOptions" option-filter-prop="label" option-label-prop="label" :max-tag-count="2" placeholder="请选择媒体" style="width: 100%" ref="select" allow-clear show-arrow @blur="channelSelectTags">
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-tree-select class="select-tagmode" tree-checkable :max-tag-count="1" :show-checked-strategy="SHOW_ALL" v-model:value="productFilter.industryList" :tree-data="IndustryOptions" :replace-fields="replaceIndustryFields" placeholder="请选择行业" style="width: 100%" tree-node-filter-prop="title" :tree-default-expanded-keys="treeDefaultExpandedKeys" allow-clear show-search>
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
            </a-tree-select>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="flexJustifyEnd">
        <a-space class="float-r">
          <a-button type="primary" @click="onFilterBtnClick()" :disabled="productTableLoading">筛选</a-button>
          <a-button @click="onResetBtnClick()" :disabled="productTableLoading">重置</a-button>
        </a-space>
      </div>
    </div>

    <!-- 产品展示表格 -->
    <div class="table_wrapper w100">
      <a-row v-hasPermission="'PRODUCT_ADD'" style="margin-bottom: 12px">
        <a-button type="primary" @click="onProductBtnClick({}, 'addProduct')" :disabled="productTableLoading"> <PlusCircleOutlined style="font-size: 14px" />新增产品</a-button>
      </a-row>
      <a-table size="small" class="product-table product_manage_table" :data-source="productData.productSource" :scroll="{ y: 300, x: productData.columns.length * 150 }" :columns="productData.columns" :row-key="record => record.id" :pagination="productPagination" :loading="productTableLoading" bordered @change="onTableChange">
        <template #operation="{ record }">
          <a v-hasPermission="'PRODUCT_EDIT'" @click="onProductBtnClick(record, 'editProduct')" class="mr10">编辑</a>
          <a v-hasPermission="'PRODUCT_VIEW'" @click="onProductBtnClick(record, 'viewProduct')" class="mr10">查看</a>
          <a v-hasPermission="'PROFIT_EDIT'" @click="onProductBtnClick(record, 'profit')" class="mr10">利益点</a>
          <!-- <a-popconfirm v-if="userGrades == 'MANAGER'" title="确定删除吗？?" ok-text="删除" cancel-text="取消" @confirm="onProductDeleteBtnClick(record)">
                <a class="mr10">删除</a>
              </a-popconfirm> -->
        </template>
        <template #channelType="{ record }">
          <span v-if="record.channelType === 'TT'">巨量引擎</span>
          <span v-else-if="record.channelType === 'GDT'">腾讯广告</span>
          <span v-else-if="record.channelType === 'KS'">磁力引擎</span>
          <span v-else>— —</span>
        </template>
        <template #projectOwner="{ record }">
          <span v-html="ownerHtml(record.projectOwner)"> </span>
        </template>
        <template #channelNum="{ record }">
          {{ record.channelNum || record.channelNum == 0 ? record.channelNum : '— —' }}
        </template>
        <template #mdaccountCount="{ record }">
          {{ record.mdaccountCount || record.mdaccountCount == 0 ? record.mdaccountCount : '— —' }}
        </template>
        <template #cost="{ record }">
          {{ record.cost || record.cost == 0 ? formatAmount(record.cost) : '— —' }}
        </template>
        <template #balance="{ record }">
          {{ record.balance || record.balance == 0 ? formatAmount(record.balance) : '— —' }}
        </template>
        <template #industry="{ record }">
          {{ (record.firstIndustryId ? columnMapValue(IndustryList, record.firstIndustryId, 'id', 'value') : '— ') + (record.secondIndustryId ? '/' + columnMapValue(IndustryList, record.secondIndustryId, 'id', 'value') : '') }}
        </template>
      </a-table>
    </div>

    <!-- 产品信息新增编辑会话框 -->
    <product-modal v-bind="productModal" v-model:visible="productModalVisible" @after-save="afterSave" />
    <!-- 删除确认弹框 -->
    <a-modal class="deleteModal" width="10rem" v-model:visible="deleteModalVisible" centered title="确认删除" :mask-closable="false" destroy-on-close @ok="deleteHandleOk" @cancel="deleteHandleCancel" cancel-text="取消" ok-text="确认">
      <span>删除后信息不予保留，请谨慎选择！</span>
    </a-modal>
    <!-- 产品信息查看会话框 -->
    <a-drawer width="50%" :title="productModalTitle" placement="right" v-model:visible="productViewDrawer" destroy-on-close :class="['product_drawer', { tab3: viewActiveKey == '3' }]" @close="drawerClose">
      <a-tabs v-model:activeKey="viewActiveKey" @change="tabsChange">
        <a-tab-pane key="1" tab="基本信息">
          <a-form>
            <a-form-item name="name" label="所属客户" :label-col="labelCol" :wrapper-col="wrapperCol" required>
              <a-input v-model:value="productModalData.customerOfCompanyName" :disabled="productDisable" />
            </a-form-item>
            <a-form-item label="累计消耗" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input v-model:value="productModalData.cost" :disabled="productDisable" />
            </a-form-item>
            <!-- <a-form-item label="当前余额" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input v-model:value="productModalData.balance" :disabled="productDisable" />
            </a-form-item> -->
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="`媒体(${productModalData.channelNum})`">
          <a-config-provider :locale="locale">
            <a-table size="small" table-layout="auto" class="media-table" :data-source="mediaData.mediaSource" :scroll="{ x: true }" :columns="mediaData.columns" :row-key="record => record.id" :pagination="mediaPagination" :loading="mediaTableLoading" bordered @change="onMediaTableChange">
              <template #channelType="{ record }">
                <span v-if="record.channelType === 'TT'">巨量引擎</span>
                <span v-else-if="record.channelType === 'GDT'">腾讯广告</span>
                <span v-else-if="record.channelType === 'KS'">磁力引擎</span>
                <span v-else>— —</span>
              </template>
              <template #mdaccountCount="{ record }">
                {{ record.mdaccountCount || record.mdaccountCount == 0 ? record.mdaccountCount : '— —' }}
              </template>
              <template #cost="{ record }">
                {{ record.cost || record.cost == 0 ? record.cost : '— —' }}
              </template>
              <template #balance="{ record }">
                {{ record.balance || record.balance == 0 ? record.balance : '— —' }}
              </template>
            </a-table>
          </a-config-provider>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="`媒体账号(${productModalData.mdaccountCount})`">
          <a-config-provider :locale="locale">
            <a-table size="small" table-layout="auto" class="media-account-table" :data-source="mediaAccountData.mediaAccountSource" :scroll="{ x: true }" :columns="mediaAccountData.columns" :row-key="record => record.projectCode" :pagination="mediaAccountPagination" :loading="mediaAccountTableLoading" bordered @change="onMediaAccountTableChange">
              <template #operation="{ record }">
                <a @click="onProductBtnClick(record, 'editProduct')" class="mr10">编辑</a>
                <a @click="onProductBtnClick(record, 'viewProduct')" class="mr10">查看</a>
              </template>
              <template #channelType="{ record }">
                <span v-if="record.channelType === 'TT'">巨量引擎</span>
                <span v-else-if="record.channelType === 'GDT'">腾讯广告</span>
                <span v-else-if="record.channelType === 'KS'">磁力引擎</span>
                <span v-else>— —</span>
              </template>
              <template #invoiceName="{ record }">
                <span>{{ record.invoiceName ? record.invoiceName : '— —' }}</span>
              </template>
              <template #mdaccountCount="{ record }">
                {{ record.mdaccountCount || record.mdaccountCount == 0 ? record.mdaccountCount : '— —' }}
              </template>
              <template #cost="{ record }">
                {{ record.cost || record.cost == 0 ? record.cost : '— —' }}
              </template>
              <template #balance="{ record }">
                {{ record.balance || record.balance == 0 ? record.balance : '— —' }}
              </template>
            </a-table>
          </a-config-provider>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
    <!-- 利益点弹框 -->
    <profit-modal v-model:visible="profitModalVisible" :select-row-data="selectRowData" @addLabelValues="addLabelValues"></profit-modal>
  </div>
</template>
<script>
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import _ from 'lodash';
import { addChineseUnit } from '@/utils/utils.js';
import { TreeSelect } from 'ant-design-vue';
import { Empty } from 'ant-design-vue';
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { getUserList } from '@/api/mediaAccount/mediaAccount.js';
import { getProductList, getAllProductList, saveAddProduct, saveUpdateProduct, getAllCustomerList, getMediaList, getAllMediaAccountList, deleteProduct, getIndustryList } from '@/api/customer/product.js';
import ProductModal from './ProductModal.vue';
import ProfitModal from './ProfitModal.vue';
export default {
  data() {
    return {
      profitModalVisible: false, //利益点弹框显隐
      selectRowData: {}, // 选择表格行数据
      IndustryOptions: [], // 所属行业树形数据
      SHOW_ALL: TreeSelect.SHOW_ALL,
      IndustryList: [], // 所属行业扁平化数据
      replaceIndustryFields: { title: 'value', key: 'id', value: 'id' }, // 替换 treeNode 中 title,value,key,children 字段
      treeDefaultExpandedKeys: [], //默认展开的节点
      ownerList: [[]],
      locale,
      userGrades: this.$store.state.oauth.userInfo.grades, //职级 (经理、组长、组员)
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      productTableLoading: false,
      mediaTableLoading: false,
      mediaAccountTableLoading: false,
      // 是否添加产品名称列表
      addProductName: false,
      // 产品名称输入框列表
      productNameInputList: [null],
      // 产品查看抽屉
      productViewDrawer: false,
      // 产品查看抽屉宽度
      drawerWidth: '20rem',
      // 产品查看tab选中页
      viewActiveKey: '1',
      // 产品新增编辑会话框
      productModalVisible: false,
      // 删除产品列表确认框
      deleteModalVisible: false,
      //要删除的产品列表索引
      deleteIndex: null,
      // 产品新增编辑会话框标题
      productModalTitle: '',
      // 产品新增编辑会话框是否禁止编辑
      productDisable: false,
      // 产品新增编辑数据
      productModalData: {},
      // 开户公司列表
      invoiceNameList: [],
      // 产品筛选条件
      productFilter: {
        limit: '30',
        page: '1',
      },
      // 产品分页配置
      productPagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      // 产品数据集合
      productData: {
        columns: [
          {
            title: '产品名称',
            dataIndex: 'projectName',
            width: 220,
          },
          {
            title: '上级产品名称',
            dataIndex: 'parentProjectName',
            width: 220,
          },
          {
            title: '客户名称',
            width: 250,
            dataIndex: 'customerOfCompanyName',
          },
          // {
          //   title: '运营产品负责人',
          //   dataIndex: 'projectOwner',
          //   slots: { customRender: 'projectOwner' },
          // },
          {
            title: '媒体数量',
            dataIndex: 'channelNum',
            slots: {
              customRender: 'channelNum',
            },
          },
          {
            title: '媒体账号数量',
            dataIndex: 'mdaccountCount',
            slots: {
              customRender: 'mdaccountCount',
            },
          },
          {
            title: '行业',
            dataIndex: 'industry',
            slots: {
              customRender: 'industry',
            },
          },
          {
            title: '累计消耗',
            dataIndex: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          // {
          //   title: '当前余额',
          //   dataIndex: 'balance',
          //   slots: {
          //     customRender: 'balance',
          //   },
          // },
          {
            title: '操作',
            // fixed: 'right',
            dataIndex: 'operation',
            width: 140,
            slots: {
              customRender: 'operation',
            },
          },
        ],
        productSource: [],
      },
      // 所有产品列表
      allProductList: [],
      // 所有客户列表
      allCustomerList: [],
      // 媒体数据筛选配置
      mediaOptions: [
        { label: '巨量引擎', value: 'TT', key: 'TT' },
        { label: '腾讯广告', value: 'GDT', key: 'GDT' },
        { label: '磁力引擎', value: 'KS', key: 'KS' },
        { label: '巨量千川', value: 'QC', key: 'QC' },
      ],
      MediaTypeObj: { TT: '巨量引擎', GDT: '腾讯广告', KS: '磁力引擎' },
      labelCol: { sm: { span: 6 }, md: { span: 6 }, lg: { span: 6 }, xl: { span: 6 }, xxl: { span: 4 } },
      wrapperCol: { sm: { span: 14 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 14 }, xxl: { span: 16 } },
      // 产品查看媒体数据
      mediaData: {
        columns: [
          {
            title: '媒体',
            dataIndex: 'channelType',
            slots: {
              customRender: 'channelType',
            },
          },
          {
            title: '累计消耗',
            dataIndex: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          // {
          //   title: '当前余额',
          //   dataIndex: 'balance',
          //   slots: {
          //     customRender: 'balance',
          //   },
          // },
          {
            title: '账号数量',
            dataIndex: 'mdaccountCount',
            slots: {
              customRender: 'mdaccountCount',
            },
          },
        ],
        mediaSource: [],
      },
      // 产品查看媒体分页配置
      mediaPagination: {
        limit: '10',
        page: '1',
        pageSize: 10,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      // 产品查看媒体账号数据
      mediaAccountData: {
        columns: [
          {
            title: '媒体账号ID',
            dataIndex: 'mdaccountId',
          },
          {
            title: '产品ID',
            dataIndex: 'projectCode',
          },
          {
            title: '产品名称',
            dataIndex: 'projectName',
          },
          {
            title: '媒体',
            dataIndex: 'channelType',
            slots: {
              customRender: 'channelType',
            },
          },
          {
            title: '开户主体',
            dataIndex: 'invoiceName',
            slots: {
              customRender: 'invoiceName',
            },
          },
          {
            title: '累计消耗',
            dataIndex: 'cost',
            slots: {
              customRender: 'cost',
            },
          },
          // {
          //   title: '当前余额',
          //   dataIndex: 'balance',
          //   slots: {
          //     customRender: 'balance',
          //   },
          // },
          {
            title: '当前消耗状况',
            dataIndex: 'mdaccountCount',
            slots: {
              customRender: 'mdaccountCount',
            },
          },
        ],
        mediaAccountSource: [],
      },
      // 产品查看媒体账号分页配置
      mediaAccountPagination: {
        limit: '10',
        page: '1',
        pageSize: 10,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
    };
  },
  components: { PlusCircleOutlined, ProductModal, ProfitModal },
  async created() {
    await this.getIndustryList();
    this.getProductList();
    this.getAllProductList();
    this.getAllCustomerList();
  },
  computed: {
    ownerHtml() {
      return owner => {
        if (owner) {
          const data = JSON.parse(owner);
          let str = '';
          data.forEach(item => {
            str += `<p> ${this.MediaTypeObj[item.mediaChannel]}—${item.operatorName}</p>`;
          });
          return str;
        } else {
          return '';
        }
      };
    },
    productModal() {
      return {
        productInfo: this.productModalData,
        allCustomerList: this.allCustomerList,
        allProductList: this.allProductList,
      };
    },
  },
  methods: {
    async addLabelValues(values) {
      let postData = _.cloneDeep(this.selectRowData);
      postData.label = values;
      let res = await saveUpdateProduct(postData);
      if (res.code == 0) {
        this.$message.success('利益点绑定成功');
        this.afterSave();
      }
    },
    // 获取所属行业树形列表
    async getIndustryList() {
      let res = await getIndustryList();
      this.IndustryOptions = res.data;
      this.IndustryList = await this.NestedIteratorRoleData(res.data);
    },

    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMapValue(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions?.filter(item => {
          return item[key] == columnValue;
        })?.[0];
        return returnOpt?.[text] || '';
      }
    },

    /**深度优先搜索扁平化数据权限数组
     * @param {*} nestedList
     * @return {*}
     */
    NestedIteratorRoleData(nestedList) {
      let vals = [];
      const dfs = nestedList => {
        for (const nest of nestedList) {
          if (nest.children && nest.children.length > 0) {
            vals.push(nest);
            dfs(nest.children);
          } else {
            vals.push(nest);
          }
        }
      };
      dfs(nestedList);
      return vals;
    },
    // 保存新增（编辑）弹框回调函数
    afterSave() {
      this.getProductList();
      this.getAllProductList();
    },
    /** 输入框失去焦点校验前后空格
     * @param {String} inputName 输入框的label
     * @param {String} obj 输入框的值所属对象, 如果非对象中的字段请传递null
     * @param {String} key 输入框的值对应字段
     * @return {*}
     */
    inputBlur(inputName, obj, key) {
      let value = this.productModalData.projectName;
      if (value && (value.indexOf(' ') === 0 || value.lastIndexOf(' ') === value.length - 1)) {
        this.$message.warning(`请不要在${inputName}前后输入空格！`);
        this.productModalData.projectName = this.productModalData.projectName.trim();
      }
      this.$refs.productFormRef.validate(key);
    },

    /** 客户名称多选下拉框失去焦点校验前后空格
     * @param {*}
     * @return {*}
     */
    customerSelectTags() {
      let value = this.productFilter.customerNameList;
      if (!Array.isArray(value)) return false;
      let trimFlag = false;
      value.forEach((item, index) => {
        if (item && (item.indexOf(' ') === 0 || item.lastIndexOf(' ') === item.length - 1)) {
          trimFlag = true;
          this.productFilter.customerNameList[index] = this.productFilter.customerNameList[index].trim();
          let itemVal = this.productFilter.customerNameList[index];
          if (!itemVal) {
            this.productFilter.customerNameList.splice(index, 1);
          }
        }
      });
      if (trimFlag) {
        this.$message.warning('请不要在客户名称前后输入空格！');
      }
    },

    /** 产品名称多选下拉框失去焦点校验前后空格
     * @param {*}
     * @return {*}
     */
    ProductSelectTags() {
      let value = this.productFilter.projectNameList;
      if (!Array.isArray(value)) return false;
      let trimFlag = false;
      value.forEach((item, index) => {
        if (item && (item.indexOf(' ') === 0 || item.lastIndexOf(' ') === item.length - 1)) {
          trimFlag = true;
          this.productFilter.projectNameList[index] = this.productFilter.projectNameList[index].trim();
          let itemVal = this.productFilter.projectNameList[index];
          if (!itemVal) {
            this.productFilter.projectNameList.splice(index, 1);
          }
        }
      });
      if (trimFlag) {
        this.$message.warning('请不要在产品名称前后输入空格！');
      }
    },

    /** 媒体多选下拉框失去焦点校验前后空格
     * @param {*}
     * @return {*}
     */
    channelSelectTags() {
      let value = this.productFilter.channelTypeList;
      if (!Array.isArray(value)) return false;
      let trimFlag = false;
      value.forEach((item, index) => {
        if (item && (item.indexOf(' ') === 0 || item.lastIndexOf(' ') === item.length - 1)) {
          trimFlag = true;
          this.productFilter.channelTypeList[index] = this.productFilter.channelTypeList[index].trim();
          let itemVal = this.productFilter.channelTypeList[index];
          if (!itemVal) {
            this.productFilter.channelTypeList.splice(index, 1);
          }
        }
      });
      if (trimFlag) {
        this.$message.warning('请不要在媒体前后输入空格！');
      }
    },
    /** 格式化数据
     * @param {Number} val 需要格式化的数据
     * @return {*}
     */
    formatAmount(val) {
      if (val === 0) {
        val = '0';
      }
      return addChineseUnit(val);
    },
    /** 获取合并的行数
     * @param {*} data   产品列表
     * @param {*} key    要合并的字段
     * @param {*} target 产品列表对应的index
     * @returns 合并的行数
     */
    getRowSpanCount(data, key, target) {
      if (!Array.isArray(data)) return 1;
      data = data.map(_ => _[key]);
      let preValue = data[0];
      const res = [[preValue]];
      let index = 0;
      for (let i = 1; i < data.length; i++) {
        if (data[i] === preValue) {
          res[index].push(data[i]);
        } else {
          index += 1;
          res[index] = [];
          res[index].push(data[i]);
          preValue = data[i];
        }
      }
      const arr = [];
      res.forEach(_ => {
        const len = _.length;
        for (let i = 0; i < len; i++) {
          arr.push(i === 0 ? len : 0);
        }
      });
      return arr[target];
    },

    /** 数据字典映射
     * @param {Array} optionlist 数据配置列表
     * @param {String}} key 匹配的key值
     * @return {*}
     */
    columnMap(optionlist, key) {
      if (key) {
        return optionlist.filter(item => {
          return item.value == key;
        })[0].label;
      }
    },

    /** 获取产品列表
     * @param {*}
     * @return {*}
     */
    async getProductList() {
      if (this.productTableLoading === true) {
        return;
      }
      this.productTableLoading = true;
      let res = await getProductList({ ...this.productFilter });
      // 所有产品
      this.productData.productSource = res.page && res.page.list;
      this.productPagination.total = res.page && res.page.totalCount;
      this.productPagination.current = res.page && res.page.currPage;
      this.productPagination.pageSize = res.page && res.page.pageSize;
      this.productTableLoading = false;
    },

    /** 获取所有产品列表
     * @param {*}
     * @return {*}
     */
    async getAllProductList() {
      let res = await getAllProductList();
      this.allProductList = res.list;
    },

    /** 获取所有客户列表
     * @param {*}
     * @return {*}
     */
    async getAllCustomerList() {
      let res = await getAllCustomerList();
      this.allCustomerList = res.list;
    },

    /** 处理产品信息
     * @param {Object} data 选中的行数据
     * @param {String} operation 处理是新增('add')/编辑('edit')/查看('view')
     * @return {*}
     */
    onProductBtnClick(data, operation) {
      // 产品信息处理
      this.productModalData = JSON.parse(JSON.stringify(data));
      this.productModalData.cost = data.cost || 0;
      this.productModalData.balance = data.balance || 0;
      this.productDisable = operation === 'viewProduct';
      // 设置产品信息会话框title
      if (operation == 'profit') {
        this.selectRowData = _.cloneDeep(data);
        this.profitModalVisible = true;
      } else if (operation === 'viewProduct') {
        this.productViewDrawer = true;
        this.productModalTitle = `${data.projectName}产品信息`;
        this.getMediaList(data.projectCode);
        this.getAllMediaAccountList(data.projectCode);
      } else {
        this.productModalVisible = true;
      }
    },
    /** 删除产品
     * @param {*} record
     * @return {*}
     */
    onProductDeleteBtnClick(record) {
      deleteProduct([record.projectCode]).then(res => {
        if (res.code === 0) {
          this.$message.success('已删除');
          this.getProductList();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    /** 获取产品的媒体列表
     * @param {String} projectCode 产品code
     * @return {*}
     */
    getMediaList(projectCode) {
      let postData = {
        projectCode: projectCode,
        channelTypeList: ['TT', 'GDT', 'KS'],
        mdaccountIds: this.productModalData.mdaccountIds,
        page: this.mediaPagination.page,
        limit: this.mediaPagination.limit,
        pageSize: this.mediaPagination.pageSize,
      };
      getMediaList(postData).then(res => {
        this.mediaData.mediaSource = res.page && res.page.list;
        this.mediaPagination.total = res.page && res.page.totalCount;
        this.mediaPagination.current = res.page && res.page.currPage;
        this.mediaPagination.pageSize = res.page && res.page.pageSize;
      });
    },

    /** 获取产品的所有媒体账户列表
     * @param {String} projectCode 产品code
     * @return {*}
     */
    getAllMediaAccountList(projectCode) {
      let postData = {
        projectCode: projectCode,
        mdaccountIds: this.productModalData.mdaccountIds,
        page: this.mediaAccountPagination.page,
        limit: this.mediaAccountPagination.limit,
        pageSize: this.mediaAccountPagination.pageSize,
      };
      getAllMediaAccountList(postData).then(res => {
        this.mediaAccountData.mediaAccountSource = res.page && res.page.list;
        this.mediaAccountPagination.total = res.page && res.page.totalCount;
        this.mediaAccountPagination.current = res.page && res.page.currPage;
        this.mediaAccountPagination.pageSize = res.page && res.page.pageSize;
      });
    },

    /** 表格分页切换
     * @param {Object} pagination 分页信息
     * @return {*}
     */
    onTableChange(pagination) {
      // 产品表格切换处理
      this.productPagination.page = pagination.current.toString();
      this.productPagination.limit = pagination.pageSize.toString();
      this.productPagination.pageSize = pagination.pageSize;
      this.productFilter.page = pagination.current.toString();
      this.productFilter.limit = pagination.pageSize.toString();
      this.productFilter.pageSize = pagination.pageSize;
      this.getProductList();
    },

    /** 媒体表格分页切换
     * @param {Object} pagination 分页信息
     * @return {*}
     */
    onMediaTableChange(pagination) {
      this.mediaPagination.page = pagination.current.toString();
      this.mediaPagination.limit = pagination.pageSize.toString();
      this.mediaPagination.pageSize = pagination.pageSize;
      this.getMediaList(this.productModalData.projectCode);
    },

    /** 媒体账户表格分页切换
     * @param {Object} pagination 分页信息
     * @return {*}
     */
    onMediaAccountTableChange(pagination) {
      this.mediaAccountPagination.page = pagination.current.toString();
      this.mediaAccountPagination.limit = pagination.pageSize.toString();
      this.mediaAccountPagination.pageSize = pagination.pageSize;
      this.getAllMediaAccountList(this.productModalData.projectCode);
    },

    /** 筛选
     * @param {*}
     * @return {*}
     */
    onFilterBtnClick() {
      // 产品表格筛选
      if (this.productFilter.channelTypeList && this.productFilter.channelTypeList.length > 0) {
        this.productData.columns.splice(3, 1, {
          title: '媒体',
          dataIndex: 'channelType',
          slots: {
            customRender: 'channelType',
          },
        });
      } else {
        this.productData.columns.splice(3, 1, { title: '媒体数量', dataIndex: 'channelNum' });
      }
      this.productFilter.limit = this.productPagination.pageSize.toString();
      this.productFilter.page = '1';
      this.getProductList();
    },

    /** 重置筛选
     * @param {*}
     * @return {*}
     */
    onResetBtnClick() {
      this.productFilter = {
        limit: '30',
        page: '1',
      };
      this.productData.columns.splice(2, 1, { title: '媒体数量', dataIndex: 'channelNum' });
      this.getProductList();
    },

    /** 确认删除产品列表
     * @param {*}
     * @return {*}
     */
    deleteHandleOk() {
      let index = this.deleteIndex;
      this.deleteModalVisible = false;
      this.productNameInputList.splice(index, 1);
      this.productNameInputList.forEach((item, itemIndex) => {
        this.productModalData['projectName' + itemIndex] = item;
      });
      this.productModalData['projectName' + this.productNameInputList.length] = null;
    },
    /** 取消确认删除产品列表
     * @param {*}
     * @return {*}
     */
    deleteHandleCancel() {
      this.deleteModalVisible = false;
    },

    /** 删除产品列表
     * @param {Number} index
     * @return {*}
     */
    deleteProductNameList(index) {
      this.deleteIndex = index;
      this.deleteModalVisible = true;
    },

    /** 产品列表输入值变化
     * @param {String} value 产品输入值
     * @param {*} index 产品列表所在index
     * @return {*}
     */
    productNameChange(value, index) {
      this.productModalData['projectName' + index] = this.productNameInputList[index];
    },

    /** 产品列表失去焦点
     * @param {Number} index 产品列表索引
     * @return {*}
     */
    productNameBlur(index) {
      let value = this.productNameInputList[index];
      if (value && (value.indexOf(' ') === 0 || value.lastIndexOf(' ') === value.length - 1)) {
        this.$message.warning(`请不要在产品${index + 1}名称前后输入空格！`);
        this.productNameInputList[index] = this.productNameInputList[index].trim();
      }
      this.$refs.productFormRef.validate('projectName' + index);
    },

    /** 产品信息弹窗取消
     * @param {*}
     * @return {*}
     */
    onProductModalCancel() {
      this.ownerList = [[]];
      this.$refs.productFormRef.clearValidate();
      this.productModalVisible = false;
      this.addProductName = false;
      this.productNameInputList = [];
      this.productModalData = {};
    },

    /** 产品信息查看抽屉关闭
     * @param {*}
     * @return {*}
     */
    drawerClose() {
      this.productViewDrawer = false;
      this.mediaPagination = {
        limit: '10',
        page: '1',
        pageSize: 10,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      };
      this.mediaAccountPagination = {
        limit: '10',
        page: '1',
        pageSize: 10,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      };
      this.viewActiveKey = '1';
      this.drawerWidth = '20rem';
    },

    /** 标签页切换
     * @param {*} key 切换的tabkey
     * @return {*}
     */
    tabsChange(key) {
      this.drawerWidth = key === '3' ? '30rem' : '20rem';
    },
  },
};
</script>
<style lang="less">
.product_drawer {
  .ant-drawer-content-wrapper {
    overflow: auto;
    .ant-drawer-content {
      min-width: 500px;
    }
  }
  &.tab3 .ant-drawer-content {
    min-width: 900px;
  }
}
.table_wrapper {
  .ant-table-body {
    overflow-y: scroll !important;
  }
}
</style>
<style lang="less" scoped>
.product_manage {
  display: flex;
  flex-direction: column;
  .filter_row {
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
    :deep(.ant-form-item) {
      margin-right: 10px;
      margin-bottom: 0 !important;
    }
  }
  .table_wrapper {
    flex-grow: 1;
    padding: 12px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
  }
}

:deep(.product_manage_table) {
  .ant-table-scroll .ant-table-body {
    max-height: calc(100vh - 290px) !important;
  }
  .ant-table-pagination {
    margin-top: 12px;
    margin-bottom: 0 !important;
  }
}
</style>